exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-2-js": () => import("./../../../src/pages/home2.js" /* webpackChunkName: "component---src-pages-home-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old-index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-portfolio-computer-repair-js": () => import("./../../../src/pages/portfolio/computer-repair.js" /* webpackChunkName: "component---src-pages-portfolio-computer-repair-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-server-installs-js": () => import("./../../../src/pages/portfolio/server-installs.js" /* webpackChunkName: "component---src-pages-portfolio-server-installs-js" */),
  "component---src-pages-portfolio-web-design-js": () => import("./../../../src/pages/portfolio/web-design.js" /* webpackChunkName: "component---src-pages-portfolio-web-design-js" */),
  "component---src-pages-portfolio-workflow-automation-js": () => import("./../../../src/pages/portfolio/workflow-automation.js" /* webpackChunkName: "component---src-pages-portfolio-workflow-automation-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

